import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PartialExecution, PartialExecutionQuantityUpdateRequestFormConfig } from './partial-execution.model';
import { DatePipe, DecimalPipe, LowerCasePipe } from '@angular/common';
import { AvatarCardComponent } from '../shared/avatar-card.component';
import { ButtonPropsModel, DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrdersService } from '../orders/orders.service';
import { QUANTITY_TYPE_VALUES } from '../shared/code-name-value.model';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ButtonModel } from '@syncfusion/ej2-buttons';
import { NGXLogger } from 'ngx-logger';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'of-partial-executions-card',
    imports: [
        AvatarCardComponent,
        DatePipe,
        DecimalPipe,
        DialogModule,
        MatProgressSpinnerModule,
        NumericTextBoxModule,
        RadioButtonModule,
        ReactiveFormsModule,
        TextBoxModule,
        ToastComponent
    ],
    template: `
    @if (partialExecutions) {
      <div class="card mb-3">
        <div class="card-header d-flex justify-content-between px-2">
          <h5 class="card-title m-0">EXECUTIONS</h5>
        </div>
        <div class="card-body p-0">
          <table class="table m-0">
            <thead>
              <tr>
                <th>Recorded</th>
                <th>Transaction Ref.</th>
                <th class="text-end">Quantity / Nominal</th>
                <th class="text-center">Recorded by</th>
                <th>Comments</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (partialExecution of partialExecutions; track $index) {
                <tr>
                  <td class="align-middle">
                    {{ partialExecution.created | date: 'dd/MM/yyyy HH:mm' }}
                    @if (partialExecution.isFinalExecution) {
                      <span class="badge text-success-emphasis bg-success-subtle ms-2">Final</span>
                    }
                  </td>
                  <td class="align-middle">{{ partialExecution.transactionRefNumber }}</td>
                  <td class="align-middle text-end">
                    {{ partialExecution.quantity | number: '1.0-10' }}
                    @if (partialExecution.quantityType) {
                      <small [title]="partialExecution.quantityType.name"
                        >({{ partialExecution.quantityType.name[0] }})</small
                      >
                    }
                  </td>
                  <td class="d-flex justify-content-center">
                    <of-avatar-card [user]="partialExecution.createdBy"></of-avatar-card>
                  </td>
                  <td class="align-middle">
                    @if (partialExecution.comments) {
                      <div [innerHTML]="partialExecution.comments"></div>
                    }
                  </td>
                  <td>
                    <button
                      class="btn border-0"
                      (click)="showEditDialog(partialExecution)"
                      [disabled]="!partialExecution.id"
                    >
                      <i class="bi bi-pencil-square" title="Update quantity"></i>
                    </button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }

    <ejs-dialog
      #dgEdit
      [showCloseIcon]="true"
      header="UPDATE QUANTITY"
      width="350px"
      [isModal]="true"
      [visible]="false"
      [buttons]="editDialogButtons"
    >
      <ng-template #content>
        <form [formGroup]="editForm">
          <!-- Quantity Type -->
          <div style="width: 320px" class="d-flex gap-3 mb-2 align-items-center">
            <small>Execution quantity type *:</small>
            @for (quantityType of quantityTypeValues; track $index) {
              <ejs-radiobutton
                [label]="quantityType.name"
                [value]="quantityType.code"
                name="radQuantityType"
                formControlName="newQuantityType"
              ></ejs-radiobutton>
            }
          </div>
          <div class="d-flex gap-3 mb-2 align-items-end">
            <ejs-numerictextbox
              formControlName="newQuantity"
              format="#,###.#####"
              [placeholder]="lblQuantity"
              floatLabelType="Auto"
              [showSpinButton]="false"
              [min]="0"
            ></ejs-numerictextbox>
            <small>{{ suffixQuantity }}</small>
          </div>
          <ejs-textbox
            formControlName="comments"
            placeholder="Comments *"
            multiline="true"
            floatLabelType="Auto"
            style="height: 100px"
          ></ejs-textbox>
        </form>
      </ng-template>
    </ejs-dialog>

    <of-toast #toastEditAction [showCloseButton]="false">
      <div id="title">PROCESSING</div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ processingMessage }}
        </div>
      </div>
    </of-toast>
  `
})
export class PartialExecutionsCardComponent {
  public editForm: FormGroup = new FormGroup({});
  public submitting = false;
  public lblQuantity: string | null = null;
  public suffixQuantity: string | null = null;
  public quantityTypeValues = Object.values(QUANTITY_TYPE_VALUES);
  public editDialogButtons: ButtonPropsModel[] = [
    {
      click: this.hideEditDialog.bind(this),
      buttonModel: {
        content: 'CANCEL'
      }
    },
    {
      click: this.updatePartialExecutionQuantity.bind(this),
      buttonModel: {
        content: 'SEND',
        disabled: true
      }
    }
  ];
  public processingMessage = '';

  @Input()
  public partialExecutions: PartialExecution[] | null = null;

  @Input()
  public currency: string | null = null;

  @Input()
  public orderId: number | null = null;

  @ViewChild('dgEdit')
  public editDialog: DialogComponent | null = null;

  @ViewChild('toastEditAction')
  public toastEditAction?: ToastComponent;

  constructor(
    fb: FormBuilder,
    private ordersService: OrdersService,
    private logger: NGXLogger
  ) {
    this.editForm = fb.group<PartialExecutionQuantityUpdateRequestFormConfig>({
      comments: fb.control<string | null>(null, Validators.required),
      executionId: fb.control<string | null>(null, Validators.required),
      newQuantity: fb.control<number | null>(null, Validators.required),
      newQuantityType: fb.control<string | null>(null, Validators.required)
    });

    this.editForm.get('newQuantityType')?.valueChanges.subscribe((quantityType) => {
      this.lblQuantity = quantityType === QUANTITY_TYPE_VALUES['nominal'].code ? 'Nominal *' : 'Quantity *';
      this.suffixQuantity =
        quantityType === QUANTITY_TYPE_VALUES['nominal'].code ? this.currency || '' : 'shares / units';
    });

    this.editForm.valueChanges.subscribe(() => {
      const button = this.editDialog?.getButtons(1) as ButtonModel;

      if (button) {
        button.disabled = !this.editForm.valid;
      }
    });
  }

  public showEditDialog(partialExecution: PartialExecution): void {
    this.editForm.patchValue({
      executionId: partialExecution.id,
      newQuantity: partialExecution.quantity,
      newQuantityType: partialExecution.quantityType?.code
    });

    this.editDialog?.show();
  }

  public hideEditDialog() {
    this.editDialog?.hide();
  }

  private updatePartialExecutionQuantity(): void {
    if (!this.orderId || !this.editForm.valid) {
      return;
    }

    this.hideEditDialog();
    this.logger.log('Updating partial execution quantity...');
    this.processingMessage = 'Updating partial execution quantity...';
    this.toastEditAction?.show();
    this.ordersService
      .updatePartialExecutionQuantity$(this.orderId, this.editForm.get('executionId')?.value, this.editForm.value)
      .subscribe({
        next: () => this.handleAfterSend(true),
        error: () => this.handleAfterSend(false)
      });
  }

  private handleAfterSend(success: boolean): void {
    if (success) {
      this.processingMessage = `Success.`;
      setTimeout(() => {
        this.resetToast();
      }, 1500);
    } else {
      this.resetToast();
    }

    this.editForm?.reset();
  }

  private resetToast() {
    this.toastEditAction?.hide();
    this.processingMessage = '';
  }
}
