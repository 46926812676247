import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_FX_ORDER_WRITER, ROLE_FX_ORDER_WRITER_RESTRICTED } from '../../shared/constants';

@Component({
  selector: 'of-can-create-fx-order',
  imports: [],
  template: `
    @if (canCreate) {
      <ng-content></ng-content>
    }
  `
})
export class CanCreateFxOrderComponent {
  public canCreate: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}
  ngOnInit(): void {
    this.canCreate = this.authService.hasOneOfRoles([ROLE_FX_ORDER_WRITER, ROLE_FX_ORDER_WRITER_RESTRICTED]);
  }
}
