import { Component, ViewChild } from '@angular/core';
import { UploaderComponent, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { UploadPath } from '../../shared/attachment-uploader.component';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { OrdersService } from '../orders.service';
import {
  FlattenedOrderToAddOrUpdateWithRowNumber,
  InsertFlatOrderResponse
} from '../flattened-order-to-add-or-update.model';
import { EditService, Grid, GridModule, IEditCell, ResizeService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { concat, tap } from 'rxjs';
import { showSuccessSnackbar } from '../../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import {
  EXECUTION_INSTRUCTION_VALUES,
  EXECUTION_METHOD_VALUES,
  ORDER_TYPE_VALUES,
  QUANTITY_TYPE_VALUES,
  TIF_VALUES,
  TRANSACTION_TYPE_VALUES
} from '../../shared/code-name-value.model';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { BrokersService } from '../../brokers/brokers.service';
import { CustodiansService } from '../../custodians/custodians.service';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { ApiErrorComponent } from '../../shared/api-error.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'of-bulk-upload-orders',
  imports: [
    ApiErrorComponent,
    CheckBoxModule,
    GridModule,
    JsonPipe,
    MatProgressSpinnerModule,
    RouterModule,
    UploaderModule
  ],
  templateUrl: './bulk-upload-orders.component.html',
  providers: [EditService, ResizeService, ToolbarService]
})
export class BulkUploadOrdersComponent {
  private accessToken: string | null = null;
  public path: UploadPath = environment.tempFileUploadPath;
  public form: FormGroup = new FormGroup({});
  public parsedOrders: FlattenedOrderToAddOrUpdateWithRowNumber[] | null = null;
  public importResults: InsertFlatOrderResponse[] = [];
  public environment = environment.env;
  public submitting = false;
  public submitted = false;

  public brokerEditParams: IEditCell | null = null;
  public custodianEditParams: IEditCell | null = null;

  public transactionTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(TRANSACTION_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public quantityTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(QUANTITY_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public executionInstructionEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(EXECUTION_INSTRUCTION_VALUES),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public executionMethodEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(EXECUTION_METHOD_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public orderTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(ORDER_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public tifEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(TIF_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  @ViewChild(UploaderComponent, { static: true })
  public uploader: UploaderComponent | null = null;

  @ViewChild('gridParsedOrders')
  public gridParsedOrders: Grid | null = null;

  @ViewChild('gridImportResults')
  public gridImportResults: Grid | null = null;

  constructor(
    private authService: OrderFlowAuthService,
    private fb: FormBuilder,
    private ordersService: OrdersService,
    private snackBar: MatSnackBar,
    private brokersService: BrokersService,
    private custodiansService: CustodiansService
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      bulkOrdersFileTempPath: new FormControl<string | null>(null, Validators.required)
    });

    this.authService.getAccessToken$().subscribe((accessToken) => (this.accessToken = accessToken));

    this.brokersService.listBrokers$(false).subscribe((brokers) => {
      this.brokerEditParams = {
        params: {
          dataSource: new DataManager(brokers),
          fields: { text: 'name', value: 'code' },
          query: new Query(),
          actionComplete: () => false
        }
      };
    });

    this.custodiansService.listCustodians$(false).subscribe((custodians) => {
      this.custodianEditParams = {
        params: {
          dataSource: new DataManager(custodians),
          fields: { text: 'name', value: 'code' },
          query: new Query(),
          actionComplete: () => false
        }
      };
    });
  }

  public addHeaders(args: any): void {
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${this.accessToken}`);

    if (this.form.get('bulkOrdersFileTempPath')?.value) {
      args.currentRequest.setRequestHeader('X-TempPath', this.form.get('bulkOrdersFileTempPath')?.value);
    }
  }

  public onSuccess(event: any): void {
    if (event.operation === 'upload') {
      const tempPath = JSON.parse(event.e.currentTarget.response).path;

      if (tempPath) {
        this.form.get('bulkOrdersFileTempPath')?.setValue(tempPath);
      }
    } else if (event.operation === 'remove') {
      this.form.get('bulkOrdersFileTempPath')?.reset();
    }
  }

  public parse(): void {
    this.ordersService
      .parseBulkOrdersFile$(this.form.get('bulkOrdersFileTempPath')?.value)
      .subscribe((parsedOrders) => {
        this.form?.reset();
        this.uploader?.clearAll();
        this.parsedOrders = parsedOrders;
      });
  }

  public downloadTemplate(): void {
    this.ordersService.getBulkOrdersFileTemplate$().subscribe((blob) => {
      saveAs(blob, 'orders-bulk-upload.template.csv');
    });
  }

  public importOrders(): void {
    if (this.parsedOrders) {
      const ordersToImport = this.parsedOrders.filter((order) => {
        const previousResult = this.importResults.find((r) => r.rowNumber === order.rowNumber);
        return !previousResult || (previousResult && !previousResult.success);
      });

      if (ordersToImport) {
        this.submitting = true;

        if (this.gridParsedOrders) {
          this.gridParsedOrders.editSettings = { allowEditing: false, allowAdding: false, allowDeleting: false };
        }

        const observables$ = ordersToImport.map((order) =>
          this.ordersService.insertFlatOrder$(order, order.rowNumber).pipe(
            tap((result) => {
              const previousResult = this.importResults.find((r) => r.rowNumber === result.rowNumber);
              if (previousResult) {
                previousResult.success = result.success;
                previousResult.orderId = result.orderId;
                previousResult.error = result.error;
              } else {
                this.importResults.push(result);
              }

              this.gridImportResults?.refresh();
            })
          )
        );

        concat(...observables$).subscribe(() => {
          showSuccessSnackbar(this.snackBar, `All orders were imported. Please check the results in the table.`);
          this.submitting = false;
          this.submitted = !this.importResults.some((r) => !r.success);

          if (this.gridParsedOrders) {
            this.gridParsedOrders.editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false };
          }
        });
      }
    }
  }
}
