import { Component, OnInit } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { DetailedOrder } from '../order.model';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../orders.service';
import { AsyncPipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderDetailsCardComponent } from './order-details-card/order-details-card.component';
import { AvatarCardComponent } from '../../shared/avatar-card.component';
import { OrderApprovalsCardComponent } from './order-approvals-card.component';
import { CheckOrderComponent } from '../../check/check-order.component';
import { OrderPretradeChecksCardComponent } from './order-pretrade-checks-card/order-pretrade-checks-card.component';
import { ApproveOrderCardComponent } from '../../approve/approve-order-card.component';
import { OrderAdditionalInfoCardComponent } from './order-additional-info-card.component';
import { OrderProcessingErrorCardComponent } from './order-processing-error-card.component';
import { OrderAuditTrailCardComponent } from './order-audit-trail-card.component';
import { AckOrderComponent } from '../../ack-order/ack-order.component';
import { PartialExecutionsCardComponent } from '../../record-partial-execution/partial-executions-card.component';

@Component({
    selector: 'of-order-details',
    imports: [
        AckOrderComponent,
        ApproveOrderCardComponent,
        AsyncPipe,
        CheckOrderComponent,
        MatProgressSpinnerModule,
        OrderAdditionalInfoCardComponent,
        OrderApprovalsCardComponent,
        OrderAuditTrailCardComponent,
        OrderDetailsCardComponent,
        OrderPretradeChecksCardComponent,
        OrderProcessingErrorCardComponent,
        PartialExecutionsCardComponent
    ],
    templateUrl: './order-details.component.html',
    styleUrl: './order-details.component.css'
})
export class OrderDetailsComponent implements OnInit {
  public order$: Observable<DetailedOrder | null> | null = null;

  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService
  ) {}

  ngOnInit(): void {
    this.setOrder();

    this.ordersService.orderUpdated.subscribe(() => {
      this.setOrder();
    });
  }

  private setOrder(): void {
    this.order$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const orderIdStr = params.get('id');

        if (orderIdStr) {
          return this.ordersService.getOrder$(Number.parseInt(orderIdStr, 10));
        } else {
          return of(null);
        }
      })
    );
  }
}
