import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import {
  ROLE_FX_ORDER_READER,
  ROLE_FX_ORDER_READER_RESTRICTED,
  ROLE_FX_ORDER_WRITER,
  ROLE_FX_ORDER_WRITER_RESTRICTED,
  ROLE_ORDER_APPROVER,
  ROLE_ORDER_READER,
  ROLE_ORDER_WRITER
} from '../shared/constants';

@Component({
  selector: 'of-can-view-orders-menu',
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewOrdersMenuComponent {
  public canView: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasOneOfRoles([
      ROLE_ORDER_APPROVER,
      ROLE_ORDER_READER,
      ROLE_ORDER_WRITER,
      ROLE_FX_ORDER_WRITER,
      ROLE_FX_ORDER_WRITER_RESTRICTED,
      ROLE_FX_ORDER_READER,
      ROLE_FX_ORDER_READER_RESTRICTED
    ]);
  }
}
