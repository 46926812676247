import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable, Subject, filter, map, of, shareReplay, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OrderFlowAuthService } from './auth/order-flow-auth.service';
import { TitleService } from './core/title.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { HealthService } from './core/health.service';
import { InitialsService } from './core/initials.service';
import { InteractionStatus } from '@azure/msal-browser';
import { NavMenuComponent } from './shared/nav-menu.component';
import { SecuritiesService } from './securities/securities.service';
import { PortfoliosService } from './portfolios/portfolios.service';

@Component({
  selector: 'of-root',
  imports: [NavMenuComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css'
})
export class AppComponent implements OnInit {
  private readonly destroying$ = new Subject<void>();

  public isIframe: boolean;
  public userManual = environment.userManual;
  public feedbackForm = environment.feedbackForm;
  public production = environment.env === 'production';
  private wasUnavailable = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private authService: MsalService,
    private breakpointObserver: BreakpointObserver,
    public orderFlowAuthService: OrderFlowAuthService,
    private titleService: TitleService,
    private msalBroadcastService: MsalBroadcastService,
    private healthService: HealthService,
    private router: Router,
    private initialsService: InitialsService,
    private securitiesService: SecuritiesService,
    private portfoliosService: PortfoliosService
  ) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
  }

  public get title(): string {
    const pageTitle = this.titleService.title;

    if (pageTitle && pageTitle.length > 0) {
      return `Order Flow - ${pageTitle}`;
    } else {
      return `Order Flow`;
    }
  }

  public login(): void {
    this.orderFlowAuthService.login$().subscribe();
  }

  public logout(): void {
    this.orderFlowAuthService.logout$().subscribe();
  }

  get menuLabel$(): Observable<string> {
    return this.orderFlowAuthService.loggedIn && this.orderFlowAuthService.account
      ? this.initialsService.getInitials$(this.orderFlowAuthService.account.username)
      : of('LOGIN');
  }

  public get loggedIn(): boolean {
    return this.orderFlowAuthService.loggedIn;
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this.orderFlowAuthService.checkAndSetActiveAccount();

    if (this.orderFlowAuthService.canViewFundamentalData()) {
      this.securitiesService.checkAndLoadSecuritiesIntoCache();
      this.portfoliosService.checkAndLoadPortfoliosIntoCache();
    }

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.orderFlowAuthService.checkAndSetActiveAccount();
      });

    this.healthService.isBackendAvailableInterval$().subscribe((isAvailable) => {
      if (!isAvailable) {
        this.wasUnavailable = true;
        this.router.navigate(['/unavailable']);
      } else if (this.wasUnavailable) {
        this.wasUnavailable = false;
        this.router.navigate(['/']);
      }
    });
  }
}
