import { AsyncPipe, JsonPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ValidationErrorComponent } from '../../shared/validation-error.component';
import { CustodiansService } from '../custodians.service';
import { CustodianToAddOrUpdate, CustodianToAddOrUpdateFormConfig } from '../custodian.model';
import { environment } from '../../../environments/environment';
import { CheckBoxSelectionService, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { PortfoliosService } from '../../portfolios/portfolios.service';
import { PortfolioWithGroupKey } from '../../portfolios/portfolio.model';
import { FormControlLabelComponent } from '../../shared/form-control-label.component';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';

@Component({
    selector: 'of-custodian-form',
    imports: [
        FormControlLabelComponent,
        JsonPipe,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MultiSelectModule,
        RouterModule,
        SwitchModule,
        TextBoxModule,
        ValidationErrorComponent
    ],
    template: `
    <form [formGroup]="custodianForm">
      <div class="card mb-3">
        <div class="card-header d-flex justify-content-between">
          @if (custodianId && custodianId > 0) {
            <h5 class="card-title m-0">UPDATE CUSTODIAN {{ custodianId }}</h5>
          } @else {
            <h5 class="card-title m-0">NEW CUSTODIAN</h5>
          }

          <!-- Hidden -->
          <div class="d-flex align-items-center gap-2">
            <ejs-switch formControlName="hidden"></ejs-switch>
            <div class="text-danger">Hidden</div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex mb-3 gap-3 align-items-center">
            <!-- Code -->
            <div class="flex-fill">
              <ejs-textbox placeholder="Code" floatLabelType="Auto" formControlName="code"></ejs-textbox>
              <of-validation-error [form]="custodianForm" field="code" validationRule="required">
                Code is required</of-validation-error
              >
            </div>

            <!-- Name -->
            <div class="flex-fill">
              <ejs-textbox placeholder="Name" floatLabelType="Auto" formControlName="name"></ejs-textbox>
              <of-validation-error [form]="custodianForm" field="name" validationRule="required">
                Name is required</of-validation-error
              >
            </div>
          </div>
          <!-- Excluded portfolios -->
          <div class="d-flex flex-column gap-1">
            <of-form-control-label>Excluded portfolios</of-form-control-label>
            <ejs-multiselect
              mode="CheckBox"
              [enableGroupCheckBox]="true"
              [dataSource]="portfolios"
              [fields]="{ text: 'shortName', value: 'internalName', groupBy: 'groupKey' }"
              formControlName="excludedPortfolios"
            >
            </ejs-multiselect>
          </div>
        </div>
        <div class="card-footer">
          <a class="btn btn-outline" [routerLink]="['/custodians']">BACK</a>
          <button
            type="submit"
            class="btn btn-outline-primary"
            [disabled]="submitting || !custodianForm.valid"
            (click)="saveCustodian()"
          >
            SAVE
            @if (submitting) {
              <mat-spinner diameter="20"></mat-spinner>
            }
          </button>
        </div>
      </div>
    </form>

    @if (environment === 'development') {
      <div class="card my-3 text-warning-emphasis bg-warning-subtle">
        <div class="card-body font-monospace">
          <small>
            {{ custodianForm.getRawValue() | json }}
          </small>
        </div>
      </div>
    }
  `,
    providers: [CheckBoxSelectionService]
})
export class CustodianFormComponent implements OnInit {
  public custodianForm: FormGroup = new FormGroup({});
  public submitting = false;
  public environment = environment.env;
  public portfolios: PortfolioWithGroupKey[] | null = null;

  @Input()
  public custodianId: number | null = null;

  constructor(
    private custodiansService: CustodiansService,
    private fb: FormBuilder,
    private portfoliosService: PortfoliosService
  ) {}

  public ngOnInit(): void {
    this.custodianForm = this.fb.group<CustodianToAddOrUpdateFormConfig>({
      code: this.fb.control<string | null>(null, Validators.required),
      excludedPortfolios: this.fb.control<string[] | null>(null),
      hidden: this.fb.control<boolean | null>(null),
      name: this.fb.control<string | null>(null, Validators.required)
    });

    this.portfoliosService.listPortfolios$().subscribe((portfolios) => {
      this.portfolios = portfolios.map((p) => ({
        ...p.portfolio,
        groupKey: p.portfolio.shortName.split('_')[0]
      }));

      if (this.custodianId && this.custodianId > 0) {
        this.custodiansService.getCustodianForUpdate$(this.custodianId).subscribe((custodian) => {
          if (custodian) {
            this.setInitialValues(custodian);
          }
        });
      }
    });
  }

  public saveCustodian(): void {
    this.submitting = true;

    const observable$ =
      this.custodianId && this.custodianId > 0
        ? this.custodiansService.updateCustodian$(this.custodianId, this.custodianForm.getRawValue())
        : this.custodiansService.insertCustodian$(this.custodianForm.getRawValue());

    observable$.subscribe(() => {
      this.submitting = false;
    });
  }

  private setInitialValues(custodian: CustodianToAddOrUpdate): void {
    this.custodianForm.get('code')?.disable();
    this.custodianForm.patchValue(custodian);
  }
}
