import { BROKER_DESTINATION_EMAIL_CODE } from './constants';

export interface CodeNameItem {
  code: string;
  name: string;
}

export interface CodeNameShortItem extends CodeNameItem {
  shortName: string;
}

export interface CodeNameValueItem extends CodeNameItem {
  value: number;
}

export const APPROVAL_STATUS_VALUES: { [key: string]: CodeNameItem } = {
  pending: { code: 'Pending', name: 'Pending' },
  approved: { code: 'Approved', name: 'Approved' },
  rejected: { code: 'Rejected', name: 'Rejected' },
  noLongerRequired: { code: 'NoLongerRequired', name: 'No Longer Required' }
};

export const BROKER_DESTINATION_VALUES: CodeNameItem[] = [
  { code: BROKER_DESTINATION_EMAIL_CODE, name: 'Email' },
  { code: 'ExternalPortal', name: 'External portal' }
];

export const EXECUTION_INSTRUCTION_VALUES: CodeNameItem[] = [
  { code: 'Solicited', name: 'Solicited' },
  { code: 'Unsolicited', name: 'Unsolicited' }
];

export const EXECUTION_METHOD_VALUES: { [key: string]: CodeNameItem } = {
  grossTradeAmount: { code: 'GrossTradeAmount', name: 'Gross Trade Amount' },
  netTradeAmount: { code: 'NetTradeAmount', name: 'Net Trade Amount' },
  units: { code: 'Units', name: 'Units' }
};

export const ORDER_TYPE_VALUES: { [key: string]: CodeNameItem } = {
  limit: { code: 'LIMIT', name: 'Limit' },
  market: { code: 'MARKET', name: 'Market' }
};

export const ORDER_STATUS_VALUES: { [key: string]: CodeNameShortItem } = {
  revising: { code: 'Revising', name: 'Revising', shortName: 'Rev' },
  pendingFaUpload: { code: 'PendingFaUpload', name: 'Pending Upload to FA', shortName: 'PUL' },
  pendingPreTradeCheck: { code: 'PendingPreTradeCheck', name: 'Pending Pre-Trade Check', shortName: 'PPTC' },
  pendingCheck: { code: 'PendingCheck', name: 'Pending Check', shortName: 'PCH' },
  pendingApproval: { code: 'PendingApproval', name: 'Pending Approval', shortName: 'PA' },
  executable: { code: 'Executable', name: 'Executable', shortName: 'Exea' },
  sent: { code: 'Sent', name: 'Sent', shortName: 'Sent' },
  acknowledged: { code: 'Acknowledged', name: 'Acknowledged', shortName: 'Acked' },
  executed: { code: 'Executed', name: 'Executed', shortName: 'Exec' },
  partiallyExecuted: { code: 'PartiallyExecuted', name: 'Partially Executed', shortName: 'PExec' },
  rejected: { code: 'Rejected', name: 'Rejected', shortName: 'Rej' },
  cancelled: { code: 'Cancelled', name: 'Cancelled', shortName: 'Cxld' },
  faUploadFailed: { code: 'FaUploadFailed', name: 'Upload to FA Failed', shortName: 'U2FAF' },
  deleted: { code: 'Deleted', name: 'Deleted', shortName: 'Del' }
};

export const PRETRADE_CHECK_STATUS_VALUES: { [key: string]: CodeNameValueItem } = {
  na: { code: 'NA', name: 'N/A', value: 0 },
  passed: { code: 'Passed', name: 'Passed', value: 1 },
  pending: { code: 'Pending', name: 'Pending', value: 2 },
  warning: { code: 'Warning', name: 'Warning', value: 3 },
  breached: { code: 'Breached', name: 'Breached', value: 4 }
};

export const QUANTITY_TYPE_VALUES: { [key: string]: CodeNameItem } = {
  nominal: { code: 'Nominal', name: 'Nominal' },
  quantity: { code: 'Quantity', name: 'Quantity' }
};

export const STAFF_TYPE_VALUES: CodeNameItem[] = [
  { code: 'Approver', name: 'Approver' },
  { code: 'Checker', name: 'Checker' },
  { code: 'Partner', name: 'Partner' },
  { code: 'Preparer', name: 'Preparer' }
];

export const TIF_VALUES: { [key: string]: CodeNameItem } = {
  day: { code: 'DAY', name: 'DAY' },
  gtc: { code: 'GTC', name: 'GTC' },
  gtd: { code: 'GTD', name: 'GTD' }
};

export const TRANSACTION_TYPE_VALUES: { [key: string]: CodeNameItem } = {
  purchase: { code: 'Purchase', name: 'Purchase' },
  sell: { code: 'Sell', name: 'Sell' }
};

export const FX_SECURITY_TYPE_VALUES: { [key: string]: CodeNameItem } = {
  fxSpot: { code: 'FX Spot', name: 'FX Spot' }
};
export const FX_NET_RATE: { [key: string]: CodeNameItem } = {
  gross: { code: 'gross', name: 'Gross' },
  net: { code: 'net', name: 'Net' }
};

export const CURRENCY_VALUES: { [key: string]: CodeNameItem } = {
  AED: { code: 'AED', name: 'AED' },
  AUD: { code: 'AUD', name: 'AUD' },
  CAD: { code: 'CAD', name: 'CAD' },
  CHF: { code: 'CHF', name: 'CHF' },
  CNH: { code: 'CNH', name: 'CNH' },
  DKK: { code: 'DKK', name: 'DKK' },
  EUR: { code: 'EUR', name: 'EUR' },
  HKD: { code: 'HKD', name: 'HKD' },
  IDR: { code: 'IDR', name: 'IDR' },
  JPY: { code: 'JPY', name: 'JPY' },
  GBP: { code: 'GBP', name: 'GBP' },
  NZD: { code: 'NZD', name: 'NZD' },
  SGD: { code: 'SGD', name: 'SGD' },
  THB: { code: 'THB', name: 'THB' },
  USD: { code: 'USD', name: 'USD' },
  ZAR: { code: 'ZAR', name: 'ZAR' }
};
