import { AsyncPipe } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import { OrderFormComponent } from '../order-form/order-form.component';
import { FxOrderFormComponent as FxOrderFormComponent } from '../fx-order-form/fx-order-form.component';

export interface OrderCloningParams {
  orderId: number;
  cloneType: string | null;
}

@Component({
  selector: 'of-create-fx-order',
  imports: [AsyncPipe, FxOrderFormComponent],
  template: `<of-fx-order-form [cloningParams]="cloningParams$ | async"></of-fx-order-form>`
})
export class CreateFxOrderComponent {
  public cloningParams$: Observable<OrderCloningParams> | null = null;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.cloningParams$ = this.route.queryParamMap.pipe(
      map((params) => ({
        orderId: Number.parseInt(params.get('source') || '-1', 10),
        cloneType: params.get('cloneType') || 'default'
      }))
    );
  }
}
