<div class="card mb-3">
  <div class="card-header d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center gap-3">
      <h5 class="card-title m-0">ORDERS</h5>
      <of-can-write-orders>
        <a
          class="btn btn-sm btn-outline-primary"
          [routerLink]="['create']"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          ><i class="bi bi-plus-lg"></i> NEW</a
        >
      </of-can-write-orders>
      <div class="btn-group btn-group-sm">
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="CSV Export"
          (click)="grdOrders?.csvExport()"
        >
          <i class="bi bi-filetype-csv"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="PDF Export"
          (click)="grdOrders?.pdfExport()"
        >
          <i class="bi bi-filetype-pdf"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="Print"
          (click)="grdOrders?.print()"
        >
          <i class="bi bi-printer"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-warning border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="Send to broker"
          (click)="sendMultipleOrdersEmailToBroker()"
          [disabled]="!canSendMultipleOrders"
        >
          <i class="bi bi-envelope"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-success border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="Acknowledge"
          (click)="ackMultipleOrders()"
          [disabled]="!canAckMultipleOrders"
        >
          <i class="bi bi-hand-thumbs-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-success border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="Execute"
          (click)="executeMultipleOrders()"
          [disabled]="!canExecuteMultipleOrders"
        >
          <i class="bi bi-credit-card"></i>
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-danger border-0"
          [ngClass]="{ disabled: !gridActionsEnabled }"
          title="Cancel"
          (click)="cancelMultipleOrders()"
          [disabled]="!canCancelMultipleOrders"
        >
          <i class="bi bi-sign-stop"></i>
        </button>
      </div>
    </div>
    <div class="d-flex gap-3">
      <div class="d-flex align-items-center gap-1">
        <ejs-textbox placeholder="Keyword" [showClearButton]="true" [(ngModel)]="searchString"> </ejs-textbox>
        <ejs-daterangepicker
          placeholder="Enter a date range"
          format="dd/MM/yyyy"
          (change)="dateRangeSelected($event)"
          (cleared)="clearDateRange()"
        ></ejs-daterangepicker>
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-outline-primary border-0"
            title="Search"
            (click)="search()"
            [disabled]="searching"
          >
            <i class="bi bi-search"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-secondary border-0"
            title="Clear Search"
            (click)="resetSearch()"
            [disabled]="searching || !searched"
          >
            <i class="bi bi-x-lg"></i>
          </button>
        </div>
      </div>
      <div class="d-flex align-items-center gap-2">
        <ejs-switch (change)="onShowDeletedChange($event)" [disabled]="!gridActionsEnabled"></ejs-switch>
        <div>Show inactive</div>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <ejs-grid
      #grdOrders
      [dataSource]="orders$ | async"
      [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
      [allowFiltering]="true"
      [filterSettings]="{ enableCaseSensitivity: false, ignoreAccent: true, type: 'Excel' }"
      [allowExcelExport]="true"
      [allowPdfExport]="true"
      [allowTextWrap]="true"
    >
      <e-columns>
        <e-column field="id" headerText="#" textAlign="Right" width="36" format="N0" [isPrimaryKey]="true">
          <ng-template #template let-data>
            <a [routerLink]="[data.id]">{{ data.id }}</a>
          </ng-template>
        </e-column>
        <e-column field="transactionType" headerText="Type" width="50"></e-column>
        <e-column field="security.currency" headerText="Ccy" width="36"></e-column>
        <e-column field="quantity" headerText="Qty / Nominal" width="70" textAlign="Right">
          <ng-template #template let-data>
            {{ data.quantity | number: '1.0-10' }}
            <small [title]="data.quantityType.name">({{ data.quantityType.name[0] }})</small>
          </ng-template>
        </e-column>
        <e-column field="security.name" headerText="Security" width="150">
          <ng-template #template let-data>
            {{ data.security.name }} <small *ngIf="data.security.type">({{ data.security?.type }})</small>
          </ng-template>
        </e-column>
        <e-column field="broker.code" headerText="Broker" width="50"></e-column>
        <e-column field="custodian.code" headerText="Custodian" width="50"></e-column>
        <e-column field="portfolioWithAccount" headerText="Portfolio / Account" width="80"></e-column>
        <e-column field="status.name" headerText="Status" width="40">
          <ng-template #template let-data>
            <of-order-status-badge [status]="data.status" [useShortFormat]="true"></of-order-status-badge>
          </ng-template>
        </e-column>
        <e-column field="createdBy.name" headerText="Preparer" width="46">
          <ng-template #template let-data>
            <of-avatar-card [user]="data.createdBy"></of-avatar-card>
          </ng-template>
        </e-column>
        <e-column field="approver.name" headerText="Approver" width="48">
          <ng-template #template let-data>
            <of-avatar-card [user]="data.approver"></of-avatar-card>
          </ng-template>
        </e-column>
        <e-column headerText="" width="30">
          <ng-template #template let-data>
            <button class="btn btn-sm btn-outline-secondary border-0" [matMenuTriggerFor]="menu">
              <i class="bi bi-three-dots-vertical"></i>
            </button>
            <mat-menu #menu="matMenu">
              <of-can-preview-order [orderStatus]="data.status">
                <button mat-menu-item (click)="viewPdf(data.id)"><i class="bi bi-eye"></i> PREVIEW</button>
              </of-can-preview-order>
              <of-can-send-order [orderId]="data.id" [orderStatus]="data.status">
                <button mat-menu-item [disabled]="!gridActionsEnabled" (click)="sendEmailToBroker(data)">
                  <i class="bi bi-envelope text-warning"></i> SEND TO BROKER
                </button>
              </of-can-send-order>
              <of-can-create-order>
                <a
                  mat-menu-item
                  [routerLink]="['create']"
                  [queryParams]="{ source: data.id }"
                  class="btn"
                  [ngClass]="{ disabled: !gridActionsEnabled }"
                >
                  <i class="bi bi-copy"></i> CLONE
                </a>
                <a
                  mat-menu-item
                  [routerLink]="['create']"
                  [queryParams]="{ source: data.id, cloneType: cloneTypeWithAllocation }"
                  class="btn"
                  [ngClass]="{ disabled: !gridActionsEnabled }"
                >
                  <i class="bi bi-copy"></i> CLONE WITH ALLOCATION
                </a>
                <of-can-edit-order [orderId]="data.id" [orderStatus]="data.status">
                  <a
                    mat-menu-item
                    [routerLink]="['/orders', data.id, 'edit']"
                    class="btn"
                    [ngClass]="{ disabled: !gridActionsEnabled }"
                  >
                    <i class="bi bi-pencil-square text-warning"></i> EDIT
                  </a>
                </of-can-edit-order>
                <of-can-delete-order [orderId]="data.id" [orderStatus]="data.status">
                  <a
                    mat-menu-item
                    [routerLink]="['/orders', data.id, 'delete']"
                    class="btn"
                    [ngClass]="{ disabled: !gridActionsEnabled }"
                  >
                    <i class="bi bi-trash text-danger"></i> DELETE
                  </a>
                </of-can-delete-order>
              </of-can-create-order>
              <of-can-approve-order [orderId]="data.id" [orderStatus]="data.status">
                <a
                  mat-menu-item
                  [routerLink]="['/approve', data.id]"
                  [queryParams]="{ status: 'Approved' }"
                  class="btn"
                  [ngClass]="{ disabled: !gridActionsEnabled }"
                >
                  <i class="bi bi-check-circle text-success"></i> APPROVE
                </a>
                <a
                  mat-menu-item
                  [routerLink]="['/approve', data.id]"
                  [queryParams]="{ status: 'Rejected' }"
                  class="btn"
                  [ngClass]="{ disabled: !gridActionsEnabled }"
                >
                  <i class="bi bi-x-circle text-danger"></i> REJECT
                </a>
              </of-can-approve-order>
              <of-can-ack-order [orderStatus]="data.status">
                <button mat-menu-item (click)="ackOrder(data)">
                  <i class="bi bi-hand-thumbs-up text-success"></i> ACKNOWLEDGE
                </button>
              </of-can-ack-order>
              <of-can-record-partial-execution [orderStatus]="data.status">
                <button mat-menu-item (click)="recordPartialExecution(data)">
                  <i class="bi bi-currency-dollar text-success"></i> RECORD EXECUTION
                </button>
              </of-can-record-partial-execution>
              <of-can-cancel-order [orderStatus]="data.status">
                <button mat-menu-item (click)="cancelOrder(data)">
                  <i class="bi bi-sign-stop text-danger"></i> CANCEL
                </button>
              </of-can-cancel-order>
            </mat-menu>
          </ng-template>
        </e-column>
        <e-column type="checkbox" width="20"></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>

<of-toast #toastEmailSentProgress [showCloseButton]="false">
  <div id="title" class="d-flex justify-content-between">
    <div>SENDING EMAILS</div>
    <div>{{ emailsSentCount }}/{{ emailsSentTotal }}</div>
  </div>
  <div id="content" class="d-flex gap-2">
    <mat-spinner [diameter]="20"></mat-spinner>
    <div>
      {{ emailsSentMessage }}
    </div>
  </div>
</of-toast>

<of-toast #toastOrdersStatusUpdatedProgress [showCloseButton]="false">
  <div id="title" class="d-flex justify-content-between">
    <div>UPDATING ORDERS STATUS</div>
    <div>{{ ordersStatusUpdatedCount }}/{{ ordersStatusUpdatedTotal }}</div>
  </div>
  <div id="content" class="d-flex gap-2">
    <mat-spinner [diameter]="20"></mat-spinner>
    <div>
      {{ ordersStatusUpdatedMessage }}
    </div>
  </div>
</of-toast>
